/* eslint-disable max-len */

export const colors = {
  // TODO: remove old color palette
  // primary
  brownDark: "#332321",
  brownLite: "#564745",
  maroon: "#680F1C",
  lime: "#EAFD00",

  // secondary
  pink: "#C96D72",
  peach: "#F09F89",
  pinkLite: "#FED1C5",
  pastel: "#A35257",

  // neutral
  smoke: "#30353F",
  silver: "#D4D4D4",
  cream: "#F1EDE5",

  // ---------------

  // new primary
  lightGreen: "#B3C335",
  midGreen: "#8DA036",
  medGreen: "#4B6F49",
  darkGreen: "#354F38",
  greyGreen: "#253627",

  // new secondary
  lightOrange: "#F9E5A9",
  orange: "#E89342",
  darkOrange: "#AF6A28",

  // tertiary
  steam: "#B3FFEF",
  toxicant: "#00B38D",
  haze: "#54918E",
  zaunGray: "#8EBDBB",
  lightBlue: "#A8D1F5",

  // new neutral
  black: "#000000",
  white: "#FFFFFF",
};

export const breakpoints = {
  mobileS: "320px",
  mobile: "767px",
  tabletM: "1024px",
  tablet: "1200px",
  laptop: "1440px",
  desktop: "2560px",
};

export const eases = {
  inOut: "cubic-bezier(0.75, 0, 0.25, 1)",
  out: "cubic-bezier(0.0, 0.75, 0.25, 1)",
  in: "cubic-bezier(0.75, 0.0, 1, 0.25)",
};

export const zIndices = {
  hideBehind: -1,
  lightningClip: 1,
  championImg: 2,
  futureEkko: 2,
  swirl: 3,
  swoosh: 4,
  bgGradient: 5,
  videoOverlay: 999999, // must be high enough to appear above Riotbar
  ekko: 6,
};

export const spacing = {
  base: "16px",
  sectionPaddingMobile: "64px",
  sectionPaddingTablet: "88px",
  sectionPaddingLaptop: "128px",
};
